.contact-container {
  background-color: #f73b3c;
  min-height: 100vh;
  color: white;
  @include bg-format;
  position: relative;
  overflow-y: hidden;

}
.contact-pattern-1 {
  position: fixed;
  background-color: #f73b3c;
  top: 0;
  padding-top: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  z-index: 7;
  max-width: 2000px;
  @media only screen and (min-width:1100px) {
    display: none;
  }
}
.contact-pattern-2 {
  position: absolute;
  background-color: #f73b3c;
  bottom: 0;
  padding-bottom: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  max-width: 2000px;
}
.contact-content {
  @include s-jc-c-ai;
  flex-direction: column;
  text-align: center;
  padding-bottom: 7rem;
  @media only screen and (min-width:1100px) {
    max-width: 900px;
  }
}
.contact-header {
  padding-top: 7rem;

  .page-title {
    color: #47eba2;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    font-size: 2.5rem;
  }
}
.contact-number-container {
  background-color: rgba(0, 0, 0, 0.2);
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  padding: 1rem 0;
  width: 100%;
  @include c-jc-c-ai;
  flex-direction: column;
  margin: 4rem 0;
  .contact-number-title {
    font-size: 1.8rem;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    @media only screen and (min-width:1000px) {
      font-size: 2rem;
    }
  }
  .contact-number {
    font-size: 1.4rem;
    padding-top: 0.5rem;
    font-weight: lighter;
    @media only screen and (min-width:1000px) {
      font-size: 1.5rem;
    }
  }
}

.contact-address-container {
  @include c-jc-c-ai;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  padding: 1rem 0;
  width: 100%;
  .contact-address-title {
    font-size: 1.8rem;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    @media only screen and (min-width:1000px) {
      font-size: 2rem;
    }
  }
  .contact-address {
    font-size: 1.4rem;
    padding: 0.5rem 0 2rem 0;
    font-weight: lighter;
    max-width: 900px;
    @media only screen and (min-width:1000px) {
      font-size: 1.5rem;
    }
  }
  .map {
    // width: 350px;
    width: 98%;
    max-width: 700px;
  }
}
