.menu-container {
  background-color: #f73b3c;
  min-height: 100vh;
  color: white;
  @include bg-format;
  position: relative;
  overflow-y: hidden;
  padding-top: 2em;
}
.menu-pattern-1 {
  position: fixed;
  background-color: #f73b3c;
  top: 0;
  padding-top: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  z-index: 7;
  max-width: 2000px;

  @media only screen and (min-width:1100px) {
    display: none;
  }
}
.menu-pattern-2 {
  position: absolute;
  background-color: #f73b3c;
  bottom: 0;
  padding-bottom: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  max-width: 2000px;
}
.nav-blur {
  width: 100vw;
  position: fixed;
  top: 2.4rem;
  height: 60px;
  opacity: 1;
  background: #f73b3c;
  z-index: 4;
  
}
.menu-header {
  padding-top: 7rem;
  @include s-jc-c-ai;
  flex-direction: column;
  .page-title {
    color: #47eba2;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    font-size: 2.5rem;
  }
  .subheading {
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    font-size: 1.5rem;
    padding-top: 0.5rem;
    @media only screen and (min-width:1000px) {
      font-size: 1.8rem;
    }
  }
}
.discount-info-container {
  margin: 2rem 0;
  background-color: rgba(0, 0, 0, 0.2);
  border: solid 2px white;
  @include s-jc-c-ai;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  font-weight: 200;
  text-align: center;
  .discount-title {
    font-size: 2rem;
    font-weight: 400;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    @media only screen and (min-width:1000px) {
      font-size: 2.3rem;
    }
  }
  .discount-details {
    font-size: 1rem;
    padding: 0rem 2rem;
    @media only screen and (min-width:1000px) {
      font-size: 1.5rem;
    }
  }
}
.menu-items-container {
  margin: auto;
  @include s-jc-c-ai;
  flex-direction: column;
  margin: 0 0 5rem 0;
  //   border:solid 1px white;
}
.section-container {
  border: solid 2px white;
  color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width: 99%;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0.5rem 0;
  padding-bottom: 2rem;
}
.combo-note {
  text-align: center;
  padding: 1rem;
}
.substitution-note {
  font-weight: lighter;
  letter-spacing: 1.5px;
  text-align: center;
}
.side-orders {
  margin: auto;
  text-align: left;
  width: 90%;
  font-weight: bold;
  text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
  padding-top: 1rem;
}
.menu-items-header {
  width: 100%;
  text-align: center;
  @include c-jc-c-ai;
  position: relative;
  padding-top: 2rem;
  display: flex;
  .menu-items-line1 {
    width: 100%;
    height: 1px;
    background-color: white;
  }
  .menu-items-line2 {
    width: 100%;
    height: 1px;
    background-color: white;
  }
  .menu-items-title {
    position: relative;
    width: 100%;
    // max-width: 200px;
    padding: 0.5rem 1rem;
    font-size: 1.3rem;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    @media only screen and (min-width:1000px) {
      font-size: 2rem;
      max-width: fit-content;
    }
  }
}
.items-container {
  @include sb-jc-c-ai;
  width: 90%;
  margin: auto;
  line-height: 35px;
  font-weight: lighter;
  color: white;
  font-weight: 400;

  .item-names {
    @media only screen and (min-width:1000px) {
      font-size: 1.3rem;
      line-height: 40px;
    }
  }
  .item-prices {
    @media only screen and (min-width:1000px) {
      font-size: 1.3rem;
    }
  }
}

.family-subheading {
  text-align: center;
  font-weight: lighter;
}
.family-dinner-content {
  @include s-jc-c-ai;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
  margin: auto;
  .family-heading {
    @include sb-jc-c-ai;
    font-weight: bold;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0 0 0;
    .family-title {
      @media only screen and (min-width:1000px) {
        font-size: 1.5rem;
      }
    }
    .family-price {
      @media only screen and (min-width:1000px) {
        font-size: 1.5rem;
      }
    }
  }
  .family-items {
    text-align: left;
    .family-item {
      padding: 0.2rem 0;
      font-weight: 400;
      // font-size:1.5rem;
      line-height: 30px;
      @media only screen and (min-width:1000px) {
        font-size: 1.2rem;
      }
    }
  }
}
