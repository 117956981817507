.nav-pattern-1 {
  position: fixed;
  background-color: #f73b3c;
  top: 0;
  padding-top: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  z-index: 7;
  max-width: 2000px;
  @media only screen and (min-width:1100px) {
    display: none;
  }
}
.nav-pattern-2 {
  position: absolute;
  background-color: #f73b3c;
  bottom: 0;
  padding-bottom: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  max-width: 2000px;
  @media only screen and (min-width:1100px) {
    display: none;
  }
}
.nav-container {
  min-height: 100vh;
  @include bg-format;
  background-color: #f73b3c;
  color: white;
  position: fixed;
  min-width: 100vw;
  max-width: 100vw;
  z-index: 5;
  overflow-x: visible;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform ease-in-out 0.8s;
  @media only screen and (min-width:1100px) {
    transition: none;
    max-height: fit-content;
    min-height: fit-content;
  }
}
.nav-close-container {
  position: fixed;
  top: 3.5rem;
  right: 2rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  @media only screen and (min-width:1100px) {
    display: none;
  }
}
.nav-open-container {
  position: fixed;
  top: 3.5rem;
  right: -2.5rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  @media only screen and (min-width:1100px) {
    display: none;
  }
}

.nav-logo-container {
  @include c-jc-c-ai;
  margin: 7rem 0 2rem 0;
  @media only screen and (min-width:1100px) {
    margin: 0;
  }
  .nav-logo {
    width: 300px;
    @media only screen and (max-width:320px) {
      width: 200px;
    }
    @media only screen and (min-width:600px) {
      width: 400px;
    }
    @media only screen and (min-width:1000px) {
      width: 500px;
    }
    @media only screen and (min-width:1100px) {
      height: 70px;
      width: auto;
    }
  }
}
.nav-links-container {
  min-height: 70vh;
  @include c-jc-c-ai;
  flex-direction: column;
  @media only screen and (min-width:1100px) {
    flex-direction: row;
    justify-content: space-between;
    min-height: 40px;
    padding: 0.5rem 0;
    max-height: 90px;
    width: 100vw;
    max-width: 1100px;
  }
  .text-links {
    @include sb-jc-c-ai;
    flex-direction: column;
    @media only screen and (min-width:1100px) {
      flex-direction: row;
      width: 35vw;
      max-width: 55vw;
    }
  }
  .link-outline {
    @include c-jc-c-ai;
    width: 100vw;
    height: 70px;
    position: relative;
    @media only screen and (min-width:1000px) {
      height: 90px;
    }
    @media only screen and (min-width:1100px) {
      width: auto;
      height: auto;
    }
  }
  .nav-link {
    font-size: 2rem;
    color: inherit;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000, -0.5px 0 0 #000;
    @media only screen and (max-width:320px) {
      font-size: 1.6rem;
    }
    @media only screen and (min-width:1000px) {
      font-size: 2.5rem;
    }
    @media only screen and (min-width:1100px) {
      font-size: 1.4rem;
    }
  }
}
.active {
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  @media only screen and (min-width:1100px) {
    background: none;
    color: #47eba2 !important;
  }
  &:before {
    content: "";
    height: 100%;
    width: 8px;
    position: absolute;
    left: 0;
    background: white;
    @media only screen and (min-width:1100px) {
      display: none;
    }
  }
}
