* {
  margin: 0;
  padding: 0;
  box-sizing: none;
}
body {
  min-width: 300px;
}

.container {
  overflow-x: hidden;
  max-width: 380px;
  width: 100%;
  @media only screen and (min-width:320px) {
    max-width: 300px;
  }
  @media only screen and (min-width:350px) {
    max-width: 350px;
  }
  @media only screen and (min-width:768px) {
    max-width: 600px;
  }
  @media only screen and (min-width:1000px) {
    max-width: 800px;
  }
  @media only screen and (min-width:1100px) {
    max-width: 1100px;
  }
}
p,
a,
button {
  font-family: "Manrope", sans-serif;
}
a {
  text-decoration: none;
  color: white;
}
// Mixens
@mixin c-jc-c-ai {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin s-jc-c-ai {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin e-jc-c-ai {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@mixin sa-jc-c-ai {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@mixin se-jc-c-ai {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@mixin sb-jc-c-ai {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin c-jc-s-ai {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@mixin c-jc-e-ai {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@mixin c-jc-se-ai {
  display: flex;
  justify-content: center;
  align-items: space-evenly;
}
@mixin c-jc-sb-ai {
  display: flex;
  justify-content: center;
  align-items: space-evenly;
}

@mixin bg-format {
  overflow-x: hidden;
  flex-direction: column;
  @include s-jc-c-ai;
}

.page-404 {
  @include c-jc-c-ai;
  height:100vh;
  width:100vw;
}
@import "home";
@import "nav";
@import "menu";
@import "contact";
