.home-container {
  background-color: #f73b3c;
  max-height: 100vh;
  min-height: 100vh;
  color: white;
  @include bg-format;
  @include s-jc-c-ai;
  position: relative;
  overflow-y: hidden;
  @media only screen and (min-width:1100px) {
    justify-content: center;
  }
}
.pattern-1 {
  position: fixed;
  background-color: #f73b3c;
  top: 0;
  padding-top: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
  z-index: 7;
  @media only screen and (min-width:1100px) {
    display: none;
  }
}
.pattern-2 {
  position: absolute;
  background-color: #f73b3c;
  bottom: 0;
  padding-bottom: 1rem;
  width: 100vw;
  height: 30px;
  object-fit: cover;
  overflow-x: hidden;
}
.text-content {
  @include s-jc-c-ai;
  flex-direction: column;
  margin-top: 86px;
  @media only screen and (min-width:1100px) {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 2rem;
    justify-items: start;
    max-height: 600px;
    height: 100%;
    overflow-y: hidden;
  }
  @media only screen and (max-width:500px) {
    margin-top: 25px;
  }
}
.home-image-container {
  @include c-jc-c-ai;
  position: relative;
  @media only screen and (min-width:1100px) {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 100%;
    align-items: flex-start;
  }
  .logo-image {
    width: 300px;
    padding: 2rem 0;
    @media only screen and (max-width:320px) {
      width: 150px;
    }
    @media only screen and (max-width:768px) {
      width: 250px;
      padding: 2rem 0 1rem 0;
    }
    @media only screen and (min-width:768px) {
      width: 450px;
      padding: 2rem 0 1rem 0;
    }
    @media only screen and (min-width:1000px) {
      width: 600px;
    }
    @media only screen and (min-width:1100px) {
      width: 500px;
      padding: 0;
    }
  }
}
.address-contact-container {
  font-size: 1.3rem;
  @include c-jc-c-ai;
  flex-direction: column;
  padding-bottom: 2rem;
  @media only screen and (max-width:600px) {
    padding-bottom: 1rem;
  }
  @media only screen and (min-width:600px) {
    font-size: 1.8rem;
  }
  @media only screen and (min-width:1000px) {
    font-size: 2rem;
  }
  @media only screen and (min-width:1100px) {
    align-items: flex-start;
    grid-column: 1/2;
    grid-row: 2/3;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 0 0 15px;
  }
  .home-address {
    font-weight: lighter;
  }
  .home-phone-container {
    padding-top: 0.2rem;
    @include c-jc-c-ai;
    flex-direction: column;
    .home-phone {
      font-size: 1.5rem;
      text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000,
        -0.5px 0 0 #000;
      @media only screen and (min-width:600px) {
        font-size: 1.9rem;
        padding-top: 0.5rem;
      }
      @media only screen and (min-width:1000px) {
        font-size: 2rem;
      }
    }
  }
}
.hours-title-container {
  position: relative;
  background-color: #f73b3c;
  @media only screen and (min-width:1100px) {
    display: none;
  }
  .hours-title {
    position: relative;
    padding: 0 1rem;
    font-size: 1.5rem;
    color: #47eba2;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    padding-bottom: 0.8rem;

    @media only screen and (min-width:600px) {
      padding-bottom: 1.5rem;
      font-size: 2.2rem;
    }
  }
}
.hours-title-container-lg {
  display: none;
  @media only screen and (min-width:1000px) {
    display: block;
    .hours-title-lg {
      position: relative;
      padding: 0 1rem;
      font-size: 1.5rem;
      color: #47eba2;
      text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
      padding-bottom: 0.8rem;
      @media only screen and (min-width:1100px) {
        padding: 1rem 0 1rem 0;
        font-size: 2rem;
      }
    }
  }
}
.business-info-container {
  text-align: center;
  @include se-jc-c-ai;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 1.5rem;
  width: 100%;

  @media only screen and (min-width:1100px) {
    grid-column: 2/3;
    grid-row: 2/3;
    border-top: solid 2px white;
    border-bottom: solid 2px white;
    padding: 0;
    height: 98%;
    width: 100%;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
  @media only screen and (max-width:700px) {
    padding: 0.5rem 0;
    margin-bottom: 1rem;
  }
  .business-dates {
    font-weight: lighter;
    letter-spacing: 2px;
    @media only screen and (min-width:600px) {
      font-size: 1.5rem;
    }
    @media only screen and (min-width:1000px) {
      font-size: 1.8rem;
    }
    @media only screen and (min-width:1100px) {
      font-size: 1.2rem;
    }
  }
  .business-hours {
    @extend .business-dates;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .holiday-note {
    padding-top: 0.5rem;
    font-weight: normal;
    letter-spacing: 2px;
    @media only screen and (min-width:600px) {
      font-size: 1.5rem;
    }
    @media only screen and (min-width:1000px) {
      font-size: 1.8rem;
    }
    @media only screen and (min-width:1100px) {
      font-size: 1.2rem;
      padding-bottom: 0.8rem;
      text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000,
        -0.5px 0 0 #000;
    }
  }
}
.home-map {
  display: none;
  @media only screen and (min-width:1100px) {
    width: 100%;
    display: block;
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
.menu-button-container {
  margin: 1rem 0 2rem 0;
  @include c-jc-c-ai;
  @media only screen and (max-width:500px) {
    margin-top: 0.5rem;
  }
  .menu {
    cursor: pointer;
    background: none;
    border: 2px solid white;
    color: white;
    font-weight: bold;
    text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000,
      -0.5px 0 0 #000;
    font-size: 1.5rem;
    padding: 0.5rem 3rem;
    letter-spacing: 5px;
    border-radius: 0.4rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 2rem;
    @media only screen and (max-width:320px) {
      margin-bottom: 3rem;
    }
    @media only screen and (max-width:500px) {
      font-size: 1.2rem;
    }
    @media only screen and (min-width:1000px) {
      font-size: 1.8rem;
      padding: 1rem 5rem;
    }
    @media only screen and (min-width:1100px) {
      display: none;
    }
  }
}
.menu-button-container-lg {
  display: none;
  @media only screen and (min-width:1100px) {
    margin: 3rem 0 2rem 0;
    @include c-jc-c-ai;
    .menu-lg {
      cursor: pointer;
      background: none;
      border: 2px solid white;
      color: white;
      font-weight: bold;
      text-shadow: 0.5px 0 0 #000, 0 -0.5px 0 #000, 0 0.5px 0 #000,
        -0.5px 0 0 #000;
      font-size: 1.5rem;
      padding: 0.5rem 3rem;
      letter-spacing: 5px;
      border-radius: 0.4rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
}

// Holiday Update
.update-disclaimer {
  width: 100%;
  text-align: center;
  background-color: #19314d;
  color: white;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  font-size: 1.2rem;
}
.holiday-info {
  margin-top: 2em;
  background-color: #00c26b;
  padding: 2em 0 2em 0;
  color: black;
  text-align: center;
  border: solid 3px black;
  h2 {
    padding: 0.5em 0 0.5em 0;
    @media only screen and (min-width:850px) {
      font-size: 2rem;
    }
  }
  p {
    @media only screen and (min-width:850px) {
      font-size: 1.3rem;
    }
    padding: 1em 0 1em 0;
  }
  li {
    @media only screen and (min-width:850px) {
      font-size: 1.3rem;
    }
  }
}
